import Modal from 'react-modal';
import { ReactComponent as ModalCorner } from '../../assets/modal-corner.min.svg';
import { ReactComponent as PlaySimulate } from '../../assets/icon-ui-arrow-right.min.svg';
import styles from './ScenarioDescModal.module.scss';
import './ScenarioDescModal.global.scss';

/** ScenarioDescModal - Modal to show scenario details before playing its simulation
 * @see http://reactcommunity.org/react-modal/
 * @param {string} scenario
 * @param {?Boolean} isOpen
 * @param {?Function} onRequestClose
 * @param {?Function} onAfterClose
 * @param {?string} contentLabel
 */
export default function ScenarioDescModal({
  scenario,
  description,
  values,
  isOpen,
  onRequestClose,
  onAfterClose,
}) {
  const appRootElement = document.getElementById('root');
  if (appRootElement) Modal.setAppElement(appRootElement);

  return appRootElement ? (
    <Modal
      parentSelector={() => document.querySelector('#root > main > section')}
      overlayClassName={styles.Overlay}
      className={styles.Modal}
      isOpen={isOpen}
      closeTimeoutMS={700}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
      contentLabel={`${scenario} Scenario`}
    >
      <ModalCorner className={`${styles.ModalCorner} ${styles.TopLeft}`} />
      <ModalCorner className={`${styles.ModalCorner} ${styles.TopRight}`} />
      <ModalCorner className={`${styles.ModalCorner} ${styles.BottomLeft}`} />
      <ModalCorner className={`${styles.ModalCorner} ${styles.BottomRight}`} />
      <div className={styles.Header}>
        Scenario
        <h2>{scenario}</h2>
      </div>
      <p>{description}</p>
      <dl>
        <dt>LMP Value</dt>
        <dd>
          {values.LMP}
          {values.LMP_Trend}
        </dd>
        <div>
          <dt>MLC</dt>
          <dd>
            <span className={values.MLC === 'n/a' ? styles.na : null}>{values.MLC}</span>
            {values.MLC_Trend}
          </dd>
          <dt>MCC</dt>
          <dd>
            <span className={values.MCC === 'n/a' ? styles.na : null}>{values.MCC}</span>
            {values.MCC_Trend}
          </dd>
          <dt>NSI</dt>
          <dd>
            <span className={values.NSI === 'n/a' ? styles.na : null}>{values.NSI}</span>
            {values.NSI_Trend}
          </dd>
        </div>
      </dl>
      <button className={styles.Simulate} type="button" onClick={onRequestClose}>
        <PlaySimulate /> Simulate
      </button>
    </Modal>
  ) : null;
}
