/** strToTitleCase - Converts Text To Title Case, Such As This Sentence
 * @see https://gist.github.com/SonyaMoisset/aa79f51d78b39639430661c03d9b1058#file-title-case-a-sentence-for-loop-wc-js
 * @see https://stackoverflow.com/a/64910248/2895948
 *
 * @param {string} str
 * @returns {string}
 */
export default function strToTitleCase(str) {
  const newStr = str.toLowerCase().split(' ');
  for (let i = 0; i < newStr.length; i++) {
    newStr[i] = newStr[i].charAt(0).toUpperCase() + newStr[i].slice(1);
  }
  return newStr.join(' ');
}
