import { useState, useCallback } from 'react';
import { DeckGL } from '@deck.gl/react';
import { MapView } from '@deck.gl/core';
import { Map } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import SimTrendDown from '../../assets/simulation-trend-down.png';
import SimTrendSteady from '../../assets/simulation-trend-steady.png';
import SimTrendUp from '../../assets/simulation-trend-up.png';
// import { ReactComponent as HistoryIcon } from '../../assets/icon-history.min.svg';
import SimulationAPI from '../../apis/SimulationAPI';
import classNames from '../../utils/classNames';
import strToTitleCase from '../../utils/strToTitleCase';
import getConfig from '../../config';
import styles from './index.global.scss';
import calloutStyles from './callout.module.scss';

const { MAPBOX_TOKEN } = getConfig();

/** cleanName - Remove "line_" prefix
 * @param {string} name
 * @returns {string}
 */
const cleanName = (name) => name.replace(/line_/i, '').replace('_', ' : ');

// Test for Jest
if (typeof jest === 'undefined')
  // eslint-disable-next-line global-require, import/no-webpack-loader-syntax, import/no-unresolved
  mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAP_VIEW = new MapView({
  controller: true,
});

const glOptions = {
  powerPreference: 'high-performance',
};

function getCursor({ isDragging, isHovering }) {
  return isHovering ? 'crosshair' : 'default';
}

const navigationControlStyles = {
  top: 16,
  right: 16,
};

/** fauxTrend - Creates and <img> tag for Up/Steady/Down graphic & text
 * @param {Number} value
 * @returns {JSXElement}
 */
const fauxTrend = ({ value }) => {
  let src = SimTrendSteady;
  if (value > 50) src = SimTrendUp;
  if (value < -50) src = SimTrendDown;
  let text = `Steady at ${value}%`;
  if (value > 50) text = `Up ${value}%`;
  if (value < -50) text = `Down ${value}%`;
  return { src, text };
  // return <img src={src} alt={text} title={text} />;
};

export function ReactMap(props) {
  const { initialViewState, children, ...rest } = props;

  return (
    <DeckGL
      views={MAP_VIEW}
      // useDevicePixels={false}
      getTooltip={({ object }) => {
        if (object) {
          const {
            name,
            object: type,
            bus_type: busType,
            // For `bus`
            p_mw: mwValue,
            in_service: online,
            // For `line`
            loading_percent: loadValue,
          } = object;
          const { src, text } = fauxTrend(loadValue ?? mwValue);
          const offlineClass = classNames(calloutStyles.cornerRibbon, calloutStyles.ribbonTopRight);
          return {
            className: calloutStyles.calloutContainer,
            html: `<div class="${calloutStyles.callout}">
                    ${type === 'line' && online === 'False'
                        ? `<div class="${offlineClass}">OFFLINE</div>`
                        : ''}
                    <h2>${type === 'bus' ? 'Node' : strToTitleCase(type)}</h2>
                    <span>${busType === 'ext' ? name : strToTitleCase(cleanName(name))}</span>
                    ${type === 'bus'
                      ? `<dl>
                          <dt>Power</dt>
                          <dd>
                            ${mwValue}<abbr>㎿</abbr>
                            <img src="${src}" alt="${text}" title="${text}" />
                          </dd>
                        </dl>`
                      // line:
                      : `<dl>
                          <dt>Loading Percent</dt>
                          <dd>
                            ${loadValue}<span>%</span>
                            <img src="${src}" alt="${text}" title="${text}" />
                          </dd>
                        </dl>`
                    }
                  </div>`,
          };
        }
      }}
      glOptions={glOptions}
      getCursor={getCursor}
      initialViewState={initialViewState}
      {...rest}
    >
      {children}
      <Map
        // reuseMaps
        mapStyle="https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json"
        mapboxAccessToken={MAPBOX_TOKEN}
      />
    </DeckGL>
  );
}
