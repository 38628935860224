import { Link } from 'react-router-dom';
import Main from '../../layout/Main';
import errorStyles from '../../styles/Error.module.scss';

/** NotFound - Page View */
export default function NotFound() {
  return (
    <Main className={errorStyles.Error}>
      <article>
        <h1>
          <span data-role="emoji" aria-label="Woman Shrugging emoji">
            🤷‍♀️
          </span>
        </h1>
        {/* <p>
          ...
        </p> */}
        <Link to="/">Back to Home</Link>
      </article>
    </Main>
  );
}
