import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import NavSearchbar from '../Elasticsearch';
import { ReactComponent as InternalTile } from '../../assets/nav-internal.min.svg';
import { ReactComponent as ExternalTile } from '../../assets/nav-external.min.svg';
import { ReactComponent as MenuIcon } from '../../assets/icon-menu.min.svg';
import './index.global.scss';

/** navInit - Initialize mobile/tablet view nav button click functionality */
const navInit = () => {
  const toggle = document.querySelector('.toggle');
  const menu = document.querySelector('.menu');
  const items = document.querySelectorAll('.item');

  /** toggleMenu - Toggle mobile menu */
  function toggleMenu() {
    if (menu.classList.contains('active')) {
      menu.classList.remove('active');
      // toggle.querySelector('a').innerHTML = "<i class='fas fa-bars'></i>";
    } else {
      menu.classList.add('active');
      // toggle.querySelector('a').innerHTML = "<i class='fas fa-times'></i>";
    }
  }

  /** toggleItem - Activate Submenu */
  function toggleItem() {
    if (this.classList.contains('submenu-active')) {
      this.classList.remove('submenu-active');
    } else if (menu.querySelector('.submenu-active')) {
      menu.querySelector('.submenu-active').classList.remove('submenu-active');
      this.classList.add('submenu-active');
    } else {
      this.classList.add('submenu-active');
    }
  }

  /** closeSubmenu - Close Submenu From Anywhere */
  function closeSubmenu(e) {
    const isClickInside = menu.contains(e.target);

    if (!isClickInside && menu.querySelector('.submenu-active')) {
      menu.querySelector('.submenu-active').classList.remove('submenu-active');
    }
  }

  // Setup Event Listeners
  toggle.addEventListener('click', toggleMenu, false);
  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    if (item.querySelector('.submenu')) {
      item.addEventListener('click', toggleItem, false);
    }
    item.addEventListener('keypress', toggleItem, false);
  }
  document.addEventListener('click', closeSubmenu, false);
};

/** Nav - Header Component */
export default function Nav() {
  useEffect(() => navInit(), []);

  return (
    <nav>
      <ul className="menu">
        <li className="item">
          <NavLink to="/Historical" activeClassName="active" />
        </li>
        <li className="item">
          <NavLink to="/Real-Time" activeClassName="active" />
        </li>
        <li className="item">
          <NavLink to="/Simulation" activeClassName="active" />
        </li>
        <li className="item search">
          <NavSearchbar />
        </li>
        {/* <li className="item has-submenu">
            <NavLink to="/Services" activeClassName="active" />
            <ul className="submenu">
              <li className="subitem">
                <NavLink to="/Services/AI" activeClassName="active" />
              </li>
              <li className="subitem">
                <NavLink to="/Modeling" activeClassName="active" />
              </li>
              <li className="subitem">
                <NavLink to="/Product-Development" activeClassName="active" />
              </li>
            </ul>
          </li> */}
        {/* <li className="item button">
            <NavLink to="/Sign-In" activeClassName="active" />
          </li> */}
        <li className="item to-right">
          <InternalTile className="tile-internal" />
          <ExternalTile className="tile-external" />
          {/* Or a sign-out button, settings, etcetera */}
        </li>
        <li className="toggle">
          <MenuIcon className="menu-icon" />
        </li>
      </ul>
    </nav>
  );
}
