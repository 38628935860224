/** replaceJSX
 * RegExp search text and replace with JSX
 * @src https://gist.github.com/gdibble/2edc1ccbe6340f887b4ba3907e26cabc
 * @see https://stackoverflow.com/a/63647844
 * @param {string} str
 * @param {RegExp} find
 * @param {JSX} replace
 * @returns {JSX}
 */
export default function replaceJSX(str, find, replace) {
  const parts = str.split(find);
  const result = [];
  for (let i = 0; i < parts.length; i++) {
    result.push(parts[i]);
    if (i < parts.length - 1) result.push(replace);
  }
  return result;
}
