/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import chroma from 'chroma-js';
import Select from 'react-select';
import usePrevious from '../../hooks/usePrevious';
import randInt from '../../utils/randInt';
import getWinWidth from '../../utils/getWinWidth';
import getWinHeight from '../../utils/getWinHeight';
import Legend from '../../assets/legend.png';
import SimTrendDown from '../../assets/simulation-trend-down.png';
import SimTrendSteady from '../../assets/simulation-trend-steady.png';
import SimTrendUp from '../../assets/simulation-trend-up.png';
import SimTrendFalling from '../../assets/simulation-trend-falling.min.svg';
import SimTrendFlat from '../../assets/simulation-trend-flat.min.svg';
import SimTrendRising from '../../assets/simulation-trend-rising.min.svg';
import SimTrendSubtract from '../../assets/simulation-sparkline-subtract.min.svg';
// import { ReactComponent as HistoryIcon } from '../../assets/icon-history.min.svg';
import { ReactComponent as PanelClose } from '../../assets/icon-ui-panelclose.min.svg';
import { ReactComponent as ZoomIn } from '../../assets/button-zoom-in.min.svg';
import { ReactComponent as ZoomOut } from '../../assets/button-zoom-out.min.svg';
import { ReactComponent as Share } from '../../assets/button-share.min.svg';
import { ReactComponent as NaturalGas } from '../../assets/icon-ui-naturalgas.min.svg';
import { ReactComponent as Solar } from '../../assets/icon-ui-solar.min.svg';
import { ReactComponent as Wind } from '../../assets/icon-ui-wind.min.svg';
import ScenarioDescModal from './ScenarioDescModal';
import styles from './card.module.scss';
import './card.global.scss';

// /** cleanName - Remove "line_" prefix
//  * @param {string} name
//  * @returns {string}
//  */
// const cleanName = (name) => name.replace(/line_/i, '').replace('_', ' : ');

// /** FauxTrend - Creates and <img> tag for Up/Steady/Down graphic & text
//  * @param {Number} value
//  * @returns {JSXElement}
//  */
// const FauxTrend = ({ value }) => {
//   let src = SimTrendSteady;
//   if (value > 50) src = SimTrendUp;
//   if (value < -50) src = SimTrendDown;
//   let text = `Steady at ${value}%`;
//   if (value > 50) text = `Up ${value}%`;
//   if (value < -50) text = `Down ${value}%`;
//   return <img src={src} alt={text} title={text} />;
// };

/** TrendImg - Map to image
 * @param {string} value
 * @returns {HTMLImageElement}
 */
const TrendImg = ({ trend }) => {
  const trends = [
    { name: 'Falling', src: SimTrendFalling },
    { name: 'Flat', src: SimTrendFlat },
    { name: 'Rising', src: SimTrendRising },
    { name: 'Subtract', src: SimTrendSubtract },
    // { name: 'Zero', src: SimTrendZero },
    // { name: 'Add', src: SimTrendAdd },
    /** Below [3] for random: */
    { name: 'Down', src: SimTrendDown },
    { name: 'Steady', src: SimTrendSteady },
    { name: 'Up', src: SimTrendUp },
  ];
  const { src } = trend
    ? trends.find(({ name }) => name === trend)
    : trends.slice(4)[Math.floor(Math.random() * 3)];
  return <img src={src} alt={trend} title={trend} />;
};

/** variation - Add slight value variation and then floor the number
 * @param {Number} value
 * @returns {Number}
 */
const variation = (value) => value
  ? Math.round(
    Math.min(Math.random() / Math.random(), Math.random() / Math.random()) + value,
  ).toLocaleString('en-US')
  : value;

/** dataTimeCaseFilter - Keep relevant data */
const dataTimeCaseFilter = (scenarioCase, refTime, { case: dataCase, timestamp: dataTime }) =>
  dataTime >= refTime && dataTime <= refTime && dataCase === scenarioCase; // After AND Before?

/** Select custom styling */
const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 12,
    height: 10,
    width: 10,
  },
});
const $DropdownBackground =
  'linear-gradient(152.36deg, rgba(8, 178, 227, 0.33) 0%, rgba(8, 178, 227, 0) 100%), #1F1745';
const $DropdownBoxShadow =
  '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 21px 24px rgba(0, 0, 0, 0.26), 0px 7.66535px 8.7604px rgba(0, 0, 0, 0.179395), 0px 3.72138px 4.25301px rgba(0, 0, 0, 0.144636), 0px 1.82429px 2.0849px rgba(0, 0, 0, 0.115364), inset 0px 0px 150px rgba(8, 178, 227, 0.25)';
const $CornflowerPurple10 = '#f4eefe';
const $SkyBlue100 = '#08b2e3';
const colorStyles = {
  container: (styles) => ({
    ...styles,
    width: '15em',
    margin: '20px auto 0',
    background: $DropdownBackground,
    boxShadow: $DropdownBoxShadow,
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    padding: '5px 10px',
    background: $DropdownBackground,
    border: '0 none',
    borderRadius: 'none',
    fontFamily: 'Roboto',
    letterSpacing: '0.15px',
    color: $CornflowerPurple10,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: $SkyBlue100,
    ':hover': { color: chroma($SkyBlue100).alpha(0.5).css() },
  }),
  menu: (styles) => ({
    // ...styles,
    borderRadius: 'none',
  }),
  menuList: (styles) => ({
    // ...styles,
    // background: $DropdownBackground,
    border: '0 none',
    borderRadius: 'none',
  }),
  option: (styles, { data: { color }, isDisabled, isFocused, isSelected }) => {
    const chromaColor = chroma(color);
    return {
      ...styles,
      padding: '13px 20px',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? chromaColor.alpha(0.1).css() //color
        : isFocused
        ? chromaColor.alpha(0.3).css()
        : undefined,
      color: $CornflowerPurple10 /*isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(chromaColor, 'white') > 2
          ? 'white'
          : 'black'
        : color*/,
      cursor: isDisabled ? 'not-allowed' : 'pointer',

      ...dot(color),

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? color
            : chromaColor.alpha(0.3).css()
          : undefined,
      },
    };
  },
  // input: (styles) => ({ ...styles, ...dot(), color: $CornflowerPurple10 }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc'), color: $CornflowerPurple10 }),
  singleValue: (styles, { data: { color } }) => ({
    ...styles,
    ...dot(color),
    color: $CornflowerPurple10,
  }),
};
// const getLabel = ({ color, label }) => (
//   <div style={{ alignItems: 'center', display: 'flex' }}>
//     {/* <span style={{ fontSize: 18, marginRight: '0.5em' }}>{icon}</span> */}
//     <span style={{ fontSize: 14 }}>{label}</span>
//   </div>
// );

/** Card - Component View */
export function Card({
  scenario,
  scenarioCase,
  simulationStep,
  simulationSteps, // TODO: use with `react-js-loading-progress-bar`
  timestamp,
  timestep,
  switchToggled,
  data: {
    simulationTypesData,
    gridHealthData: { cases, lines, nodes/*, sgen, slack*/ },
    infoPanelData: { temperatureChange, lostGenerators, naturalGas, solar, wind },
    timestampsData: { maxTimestamp, minTimestamp, steps, timestamps },
  },
  handleCaseChange,
  handleSetScenario,
  handleSetCase,
  // handleToggleTimeline,
}) {
  // Check if a mobile portrait perspective
  const winW = getWinWidth();
  const winH = getWinHeight();
  const aspectRatio = { mobile: 9 / 15, tablet: 3 / 4, viewport: winW / winH };
  const isPortraitPhone = winW / winH <= aspectRatio.mobile;
  const [panelClosed, setPanelClosed] = useState(isPortraitPhone);
  const panelClosedHandler = () => setPanelClosed(!panelClosed);
  const [scenarioModalOpen, setScenarioModalOpen] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState(scenario);
  const [scenarioCases, setScenarioCases] = useState([]);
  const [numberOfCases, setNumberOfCases] = useState(scenarioCases.length);
  const prevNumberOfCases = usePrevious(numberOfCases);
  const handleSelectedScenario = ({ value }) => {
    if (!scenarioModalOpen) {
      setSelectedScenario(value);
      setScenarioModalOpen(true);
    }
  };

  const timezoneAdjustedDate =
    timestamp &&
    new Date(timestamp * 1000).toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
  const timestring = timestamp
    ? `${new Date(timezoneAdjustedDate).toLocaleDateString()} @ ${new Date(
        timezoneAdjustedDate,
      ).toLocaleTimeString()}`
    : '';

  // NOTE: we should only iterate once on data but yolo
  const filteredLines = lines.filter(dataTimeCaseFilter.bind(null, scenarioCase, timestamp));

  // NOTE: in the future this is a good candidate for a
  // dedicated API route for the filtered data
  const filteredNodes = nodes.filter(dataTimeCaseFilter.bind(null, scenarioCase, timestamp));

  const typesOptions = simulationTypesData.map(({ color, description, scenario }) => ({
    color,
    description,
    label: scenario,
    value: scenario,
  }));
  const typeSelected = typesOptions.find(({ value }) => value === selectedScenario);

  // TODO
  const scenarioValues = {
    LMP: '34.06',
    LMP_Trend: <TrendImg trend="Subtract" />,
    MLC: '-2.14',
    MLC_Trend: <TrendImg trend="Rising" />,
    MCC: '-0.11',
    MCC_Trend: <TrendImg trend="Falling" />,
    NSI: 'n/a',
    NSI_Trend: <TrendImg trend="Flat" />,
  };

  /** scenarioCase default */
  useEffect(() => {
    if (!scenarioCases.length || scenarioCases.length !== cases.length) {
      setScenarioCases(cases);
      setNumberOfCases(cases.length);
    }

    // console.log(prevCondition,condition)
    // console.log(prevScenarioCase,scenarioCase)
    // console.log(scenarioCases)
    // console.log(prevNumberOfCases, numberOfCases)

    if (numberOfCases && numberOfCases !== prevNumberOfCases) {
      handleSetCase(scenarioCases[scenarioCases.length === 1 ? 0 : 1]);
    }
  }, [
    cases,
    handleSetCase,
    numberOfCases,
    prevNumberOfCases,
    scenarioCase,
    scenarioCases,
  ]);

  return (
    <>
      <ScenarioDescModal
        scenario={selectedScenario}
        description={typeSelected?.description}
        values={scenarioValues}
        isOpen={scenarioModalOpen}
        onRequestClose={() => setScenarioModalOpen(false)}
        onAfterClose={() => handleSetScenario(selectedScenario)}
      />
      <header>
        {typeSelected ? (
          <Select
            id="scenario"
            className={styles.scenario}
            isSearchable={false}
            blurInputOnSelect
            styles={colorStyles}
            options={typesOptions}
            // formatOptionLabel={getLabel}
            value={typeSelected}
            onChange={handleSelectedScenario}
            // onChange={({ value }) => handleSetScenario(value)}
            // selectOption={({ value }) => handleSetScenario.call(null, value)}
          />
        ) : null}
        <div className={styles.cases}>
          {cases.map((c) => (
            <button
              key={randInt()}
              type="button"
              className={c === scenarioCase ? styles.active : styles.case}
              onClick={() => handleSetCase(c)}
            >
              {c}
            </button>
          ))}
        </div>
      </header>
      {!timestring ? null : (
        <header className={styles.dateTime}>
          <span>{timestring}</span>
        </header>
      )}

      <img src={Legend} className={styles.plotLegend} alt="Legend" title="Legend" />

      <aside className={`${styles.infoPanel} ${panelClosed ? styles.hide : ''}`} alt="Info Panel">
        <div>
          <div className={styles.OuterControls}>
            <ZoomIn />
            <ZoomOut />
            <Share />
          </div>
          <h3 className={styles.CardListTitle}>
            Event details
            <PanelClose onClick={panelClosedHandler} />
          </h3>
          <div className={styles.CardDivider} />
          <div className={styles.temperatureChange}>
            <h4 className={styles.CardListItemLabel}>TEMPERATURE CHANGE:</h4>
            <span className={styles.CardListItemValue}>{variation(temperatureChange)}°F</span>
            <TrendImg trend={temperatureChange ? null : 'Steady'} />
          </div>
          <div className={styles.CardDivider} />
          <div className={styles.lostGenerators}>
            <h4 className={styles.CardListItemLabel}>LOST GENERATORS:</h4>
            <span className={styles.CardListItemValue}>
              {variation(lostGenerators)}
              <span>Units</span>
            </span>
            <TrendImg trend={lostGenerators ? null : 'Steady'} />
          </div>
          <div className={styles.CardDivider} />
          <div className={styles.naturalGas}>
            <h4 className={styles.CardListItemLabel}>
              <NaturalGas />
              NATURAL GAS:
            </h4>
            <span className={styles.CardListItemValue}>
              {variation(naturalGas)}
              <span>㎿</span>
            </span>
            <TrendImg trend={naturalGas ? null : 'Steady'} />
          </div>
          {/* <div className={styles.CardDivider} /> */}
          <div className={styles.solar}>
            <h4 className={styles.CardListItemLabel}>
              <Solar />
              SOLAR:
            </h4>
            <span className={styles.CardListItemValue}>
              {variation(solar)}
              <span>㎿</span>
            </span>
            <TrendImg trend={solar ? null : 'Steady'} />
          </div>
          {/* <div className={styles.CardDivider} /> */}
          <div className={styles.wind}>
            <h4 className={styles.CardListItemLabel}>
              <Wind />
              WIND:
            </h4>
            <span className={styles.CardListItemValue}>
              {variation(wind)}
              <span>㎿</span>
            </span>
            <TrendImg trend={wind ? null : 'Steady'} />
          </div>
        </div>

        {/* <div className={styles.CardDivider} />

        <div className={styles.Card}>
          <h3 className={styles.CardListTitle}>Nodes</h3>
          <div className={styles.CardList}>
            {filteredNodes.map(({ name, timestamp, p_mw: value }) => (
              <div className={styles.CardListItem} key={`${name}-${timestamp}}`}>
                <div>
                  <h4 className={styles.CardListItemLabel}>{cleanName(name)}</h4>
                  <div className={styles.CardListItemValue}>
                    {value}
                    <span>㎿</span>
                  </div>
                </div>
                <FauxTrend value={value} />
              </div>
            ))}
          </div>

          <div className={styles.CardDivider} />

          <h3 className={styles.CardListTitle}>Lines</h3>
          <div className={styles.CardList}>
            {filteredLines.map(({ name, timestamp, loading_percent: value }) => (
              <div className={styles.CardListItem} key={`${name}-${timestamp}`}>
                <div>
                  <h4 className={styles.CardListItemLabel}>{cleanName(name)}</h4>
                  <div className={styles.CardListItemValue}>{value}%</div>
                </div>
                <FauxTrend value={value} />
              </div>
            ))}
          </div>
        </div> */}
      </aside>
    </>
  );
}
