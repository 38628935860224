export default {
  '3A5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/INT-006-5.pdf', terms: ['INT'] },
  '7A5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/INT-006-5.pdf', terms: ['INT'] },
  '9w5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['PRC'] },
  '-A5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['PRC', 'Load Shedding'] },
  '_w5hPHwBTKIPa-Hu0ZYj': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: ['PRC', 'Load Shedding', 'Risk Factor: High'],
  },
  'Aw5hPHwBTKIPa-Hu0Zcj': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['PRC', 'Load Shedding'] },
  'BA5hPHwBTKIPa-Hu0Zcj': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['PRC', 'Load Shedding'] },
  'Cg5hPHwBTKIPa-Hu0pcZ': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: ['PRC', 'Load Shedding', 'Risk Factor: Lower'],
  },
  'Eg5hPHwBTKIPa-Hu0pca': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: ['PRC', 'load shedding', 'Risk Factor: High'],
  },
  'Ew5hPHwBTKIPa-Hu0pca': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: [
      'PRC',
      'Load Shedding',
      'Risk Factor: Lower',
      'Risk Factor: Medium',
      'Risk Factor: High',
    ],
  },
  'FA5hPHwBTKIPa-Hu0pca': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: ['PRC', 'Risk Factor: Lower'],
  },
  'FQ5hPHwBTKIPa-Hu0pca': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: ['PRC', 'load shedding', 'Risk Factor: High'],
  },
  'Fg5hPHwBTKIPa-Hu0pca': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: ['PRC', 'Load Shedding', 'Risk Factor: High'],
  },
  'HQ5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['PRC'] },
  'KA5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/TOP-003-4.pdf', terms: ['TOP'] },
  'MQ5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/TOP-003-4.pdf', terms: ['TOP'] },
  'Mw5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/TOP-003-4.pdf', terms: ['TOP', 'Risk Factor: Low'] },
  'OA5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/TOP-003-4.pdf', terms: ['TOP'] },
  '2Q5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/INT-006-5.pdf', terms: ['Interchange'] },
  '3Q5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/INT-006-5.pdf', terms: ['Interchange'] },
  '3g5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/INT-006-5.pdf', terms: ['Interchange'] },
  '4Q5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/INT-006-5.pdf', terms: ['Interchange'] },
  '4g5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/INT-006-5.pdf', terms: ['Interchange'] },
  '4w5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/INT-006-5.pdf', terms: ['Interchange'] },
  '5g5hPHwBTKIPa-Hu0ZYj': {
    filepath: '/PDF/INT-006-5.pdf',
    terms: ['Interchange', 'Risk Factor: Lower'],
  },
  '5w5hPHwBTKIPa-Hu0ZYj': {
    filepath: '/PDF/INT-006-5.pdf',
    terms: ['Interchange', 'Risk Factor: High'],
  },
  '6A5hPHwBTKIPa-Hu0ZYj': {
    filepath: '/PDF/INT-006-5.pdf',
    terms: ['Interchange', 'Risk Factor: Lower'],
  },
  '9A5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Load Shedding'] },
  '-Q5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Load Shedding'] },
  'AA5hPHwBTKIPa-Hu0Zcj': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: ['load shedding', 'Risk Factor: High'],
  },
  'Cw5hPHwBTKIPa-Hu0pcZ': {
    filepath: '/PDF/PRC-006-NPCC-2.pdf',
    terms: ['load shed', 'Risk Factor: High'],
  },
  'BQ5hPHwBTKIPa-Hu0Zcj': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['load'] },
  '_g5hPHwBTKIPa-Hu0ZYj': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Risk Factor: Medium'] },
  'DA5hPHwBTKIPa-Hu0pcZ': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Risk Factor: High'] },
  'Dg5hPHwBTKIPa-Hu0pcZ': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Risk Factor: Lower'] },
  'Fw5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Risk Factor: Medium'] },
  'GA5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Risk Factor: Lower'] },
  'GQ5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Risk Factor: Lower'] },
  'Gg5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/PRC-006-NPCC-2.pdf', terms: ['Risk Factor: High'] },
  'Mg5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/TOP-003-4.pdf', terms: ['Risk Factor: Low'] },
  'NA5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/TOP-003-4.pdf', terms: ['Risk Factor: Low'] },
  'NQ5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/TOP-003-4.pdf', terms: ['Risk Factor: Low'] },
  'Ng5hPHwBTKIPa-Hu0pca': { filepath: '/PDF/TOP-003-4.pdf', terms: ['Risk Factor: Medium'] },
};
