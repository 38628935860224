import Main from '../../layout/Main';
import classNames from '../../utils/classNames';
// import { ReactComponent as Control } from '../../assets/historical-RDT-2.0-control.min.svg';
import control from '../../assets/historical-RDT-2.0-control.png';
import { ReactComponent as EventDetailsTitle } from '../../assets/event-details-aside.min.svg';
import { ReactComponent as EventDetailsPanel } from '../../assets/event-details-inner.min.svg';
import styles from './index.module.scss';

export default function Historical() {
  return (
    <Main className={styles.Historical} title="Static">
      <div
        className={classNames(styles.centerContent, styles.RDT)}
        style={{ backgroundImage: "url('/historical-RDT-2.0-inner.min.svg')" }}
      >
        <div
          className={classNames(styles.centerContent, styles.RTOs)}
          style={{ backgroundImage: "url('/historical-RTOs-2.1-inner.min.svg')" }}
        />
      </div>
      <img className={styles.control} src={control} width="320" height="560" alt="control" />
      <div className={styles.eventDetails}>
        {/* <EventDetailsTitle title="Open Event details panel" /> */}
        <EventDetailsPanel title="Event details" />
      </div>
    </Main>
  );
}
