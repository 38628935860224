/* eslint-disable no-restricted-globals */
import { Link, HashRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRouter from './router';
import useBranding from './hooks/useBranding';
import useRedirect from './hooks/useRedirect';
import { AppLogo } from './layout/Main';
import errorStyles from './styles/Error.module.scss';
import './styles/App.global.scss';

const queryClient = new QueryClient();

/** AppWrapper - Component SubView wrapping required Providers
 * @param {ReactChildren} children
 */
const AppWrapper = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {children}
  </QueryClientProvider>
);

/** ErrorFallback - Page SubView for generic error handling functionality
 * @param {Object} error
 * @param {Function} resetErrorBoundry
 * @returns {JSXElement}
 */
const ErrorFallback = ({
  error = { message: 'Minor error, not the end of the world' },
  resetErrorBoundary,
}) => {
  // Retry deckgl rendering error
  if (error.message === 'failed to invert matrix') resetErrorBoundary();

  return (
    <AppWrapper>
      <Router>
        <article role="alert" className={errorStyles.Error}>
          <AppLogo />
          <h1>
            <span data-role="emoji" aria-label="Man Facepalming emoji">
              🤦‍♂️
            </span>
          </h1>
          <div data-role="nowrap">
            <p>
              <em>Houston, we have a problem</em>
            </p>
          </div>
          <pre>{error.message}</pre>
          <button onClick={resetErrorBoundary} type="button">
            Try again
          </button>
        </article>
      </Router>
    </AppWrapper>
  );
};

/** resetAppState - Reset the state of your app so the error doesn't happen again */
const resetAppState = () => {
  // ...
};

/** App - Main Container */
export default function App() {
  useRedirect();
  useBranding();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={resetAppState}>
      <AppWrapper>
        <AppRouter />
      </AppWrapper>
    </ErrorBoundary>
  );
}

export { ErrorFallback };
