import { useEffect, useCallback, useState } from 'react';
import Main from '../../layout/Main';
import {
  DeckglLayer as GridHealthLayer,
  Card as GridHealthCard,
  // TempTimeline as GridHealthTimeline,
  useGridHealthData,
} from '../../components/GridHealth';
// import Loading from '../../components/Loading';
import { ReactMap as Map } from '../../components/Map';
import Timeline from '../../components/Timeline';
import ToggleSwitch from '../../components/ToggleSwitch';
import { DeckglLayer as WeatherEchoTops } from '../../components/WeatherEchoTops';
import { DeckglLayer as WeatherMRMS } from '../../components/WeatherMRMS';
import { DeckglLayer as WeatherReflectivity } from '../../components/WeatherReflectivity';
import usePrevious from '../../hooks/usePrevious';
import { ReactComponent as HistoryIcon } from '../../assets/icon-history.min.svg';
import getConfig from '../../config';
import styles from './index.module.scss';

const { REGIONS } = getConfig();

const TIMESTEP_AUTOPLAY_DELAY = 500;

/** Simulation - Page View */
export default function Simulation() {
  const [autoIncrementTimeStep, setAutoIncrementTimeStep] = useState(true);
  const [timestep, setTimestep] = useState(0);
  const [windowResizing, setWindowResizing] = useState(false);
  const [windowResized, setWindowResized] = useState(0);
  const timeline = true; // const [timeline, setTimeline] = useState(false);
  const [scenario, setScenario] = useState(`${REGIONS[0]} Normal`);
  const [scenarioCases, setScenarioCases] = useState([]);
  const [scenarioCase, setScenarioCase] = useState('base');
  const {
    simulationOptionsData: { isLoading: simulationOptionsIsLoading, data: initialViewState },
    simulationTypesData: { isLoading: simulationTypesIsLoading, data: simulationTypesData },
    gridHealthData: { isLoading: gridHealthIsLoading, data: gridHealthData } = {},
    infoPanelData: { isLoading: infoPanelIsLoading, data: infoPanelData },
    timestampsData: { isLoading: timestampsIsLoading, data: timestampsData },
  } = useGridHealthData(
    {
      scenario,
      // setSimulationStep: useCallback((step) => setSimulationStep(step), []),
      // setSimulationSteps: useCallback((steps) => setSimulationSteps(steps), []),
    },
    // { enabled: Boolean(scenario) }, // Call react-query hook scenarioally
  );

  /** restartAutoPlayback - Reset timestamp and incrementation */
  const restartAutoPlayback = () => {
    setTimestep(0);
    setAutoIncrementTimeStep(true);
  };

  // /** handleCaseChange - Sets the ToggleSwitch state
  //  * @param {Boolean} checked
  //  */
  // const handleCaseChange = useCallback((checked) => {
  //   setSwitchToggled(checked);
  //   restartAutoPlayback();
  // }, []);

  /** handleSetCase - Sets the active condition
   * @param {string} c
   */
  const handleSetCase = useCallback((newCase) => {
    setScenarioCase(newCase);
    restartAutoPlayback();
  }, []);

  /** handleSetScenario - Sets the active condition
   * @param {string} c
   */
  const handleSetScenario = useCallback((newScenario) => {
    setScenario(newScenario);
    restartAutoPlayback();
  }, []);

  /** handleSliderChange - Updates timeStep from slider input value
   * @param {Object:ChangeEvent} e
   */
  const handleSliderChange = useCallback((e) => setTimestep(Number(e.target.value)), []);

  // /** handleToggleTimeline - Shows/hides the faux Timeline svg by toggling a div#root className
  //  * @param {Object:ChangeEvent} e
  //  */
  // const handleToggleTimeline = (e) => {
  //   e.stopPropagation();
  //   setTimeline(!timeline);
  //   const root = document.getElementById('root');
  //   const button = e.target.nodeName === 'svg' ? e.target.parentNode : e.target;
  //   root.classList[timeline ? 'remove' : 'add']('showTimeline');
  //   button.classList[timeline ? 'remove' : 'add']('active');
  // };
  const rootElm = document.getElementById('root');
  if (rootElm) rootElm.classList.add('showTimeline');

  /** Resizing-stops listener */
  useEffect(() => {
    let timeout;
    const handleResize = () => {
      clearTimeout(timeout);
      setWindowResizing(true);
      timeout = setTimeout(() => {
        setWindowResizing(false);
        setWindowResized(windowResized + 1);
      }, 200);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // Cleanup
  }, [windowResized]);

  /** Timestep [auto-incrementer] playback */
  useEffect(() => {
    if (timestampsIsLoading || !timestampsData.timestamps.length) return;
    function inc() {
      if (autoIncrementTimeStep && timestep < timestampsData.timestamps.length - 1)
        setTimestep((t) => t + 1);
      else {
        clearInterval(interval);
        setAutoIncrementTimeStep(false);
      }
    }
    const interval = setInterval(inc, TIMESTEP_AUTOPLAY_DELAY);
    return () => clearInterval(interval);
  }, [autoIncrementTimeStep, timestampsData, timestampsIsLoading, timestep]);

  if (
    simulationOptionsIsLoading || // viewState
    simulationTypesIsLoading ||
    gridHealthIsLoading ||
    infoPanelIsLoading ||
    timestampsIsLoading
  ) {
    return null;
  }
  // Later, maybe something better than:
  // { simulationOptionsIsLoading || // viewState
  //   simulationTypesIsLoading ||
  //   infoPanelIsLoading ||
  //   timestampsIsLoading ||
  //   gridHealthIsLoading ||
  //   !gridHealthData.cases?.length ||
  //   !gridHealthData.lines?.length ||
  //   !gridHealthData.nodes?.length ? (
  //     <Loading />
  //   ) : (
  //     <>
  //       <Main className={styles.Simulation}>
  //         <Map viewState={viewState} /*onViewStateChange={setViewState}*/ layers={layers} />
  //         <header className={styles.subheader}>
  //           <div>
  //             <h1>Simulation of Electric Transmission Network</h1>
  //             Running {condition} scenario with {scenarioCase} case
  //           </div>
  //         </header>
  //         <GridHealthCard
  //           condition={condition}
  //           data={{ simulationTypesData, gridHealthData, infoPanelData, timestampsData }}
  //           handleCaseChange={handleCaseChange}
  //           handleSetScenario={handleSetScenario}
  //           handleSliderChange={handleSliderChange}
  //           // handleToggleTimeline={handleToggleTimeline}
  //           scenarioCase={scenarioCase}
  //           switchToggled={switchToggled}
  //           timestamp={timestamp}
  //           timestep={timestep}
  //         />
  //         {/* <GridHealthTimeline /> */}
  //       </Main>
  //       <Timeline />
  //     </>
  //   )}

  const timestamp = timestampsData.timestamps[timestep];
  const { maxTimestamp, minTimestamp, timeStep } = timestampsData;

  const layers = [
    new WeatherEchoTops({ visible: true }),
    new WeatherReflectivity({ visible: true }),
    new WeatherMRMS({ visible: true }),
    !gridHealthData.lines.length
      ? []
      : new GridHealthLayer({
          data: gridHealthData,
          maxTimestamp,
          minTimestamp,
          scenarioCase,
          timestamp,
          timestep,
          timeStep,
        }),
  ];

  return (
    <>
      <Main className={styles.Simulation}>
        <Map initialViewState={initialViewState} layers={layers} />
        {/* <header className={styles.subheader}>
          <div>
            <h1>Simulation of Electric Transmission Network</h1>
            Running {scenario} scenario with {scenarioCase} case
          </div>
        </header> */}
        <GridHealthCard
          scenario={scenario}
          scenarioCase={scenarioCase}
          // simulationStep={simulationStep}
          // simulationSteps={simulationSteps}
          timestamp={timestamp}
          timestep={timestep}
          data={{ simulationTypesData, gridHealthData, infoPanelData, timestampsData }}
          // handleCaseChange={handleCaseChange}
          handleSetCase={handleSetCase}
          handleSetScenario={handleSetScenario}
          handleSliderChange={handleSliderChange}
          // handleToggleTimeline={handleToggleTimeline}
        />
        {/* <GridHealthTimeline /> */}
      </Main>
      <Timeline />
    </>
  );
}
