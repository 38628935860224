// import { ReactComponent as Play } from '../../assets/button-play.min.svg';
import { ReactComponent as TimelineTile } from '../../assets/TimelineTile.min.svg';
import styles from './index.module.scss';

/** Footer - Page Component */
export default function Timeline() {
  return (
    <footer className={styles.Timeline} alt="Timeline">
      {/* <Play className={styles.Play}  /> */}
      <TimelineTile className={styles.TimelineTile} />
    </footer>
  );
}
