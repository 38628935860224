import { CompositeLayer } from '@deck.gl/core';
import { BitmapLayer } from '@deck.gl/layers';
import { TileLayer } from '@deck.gl/geo-layers';

// REFERENCE: https://mesonet.agron.iastate.edu/ogc/

const urls = [
  'https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-eet-900913/{z}/{x}/{y}.png',
  'https://mesonet1.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-eet-900913/{z}/{x}/{y}.png',
  'https://mesonet2.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-eet-900913/{z}/{x}/{y}.png',
  'https://mesonet3.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-eet-900913/{z}/{x}/{y}.png',
];

function renderer(props: any) {
  const { data, tile } = props;
  const { bbox } = tile;

  const bounds = [bbox.west, bbox.south, bbox.east, bbox.north];

  return new BitmapLayer(props, {
    data: null,
    image: data,
    bounds,
  });
}

export class DeckglLayer extends CompositeLayer<any, any> {
  renderLayers() {
    const { visible } = this.props;

    return new TileLayer({
      id: 'echotops',
      data: urls,
      renderSubLayers: renderer,
      maxRequests: 20,
      tileSize: 512,
      opacity: 0.35,
      visible,
    });
  }
}

DeckglLayer.componentName = 'WeatherEchoTops';
