import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, LineLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers';
import { TripsLayer } from '@deck.gl/geo-layers';
import { DataFilterExtension } from '@deck.gl/extensions';
import invlerp from '../../utils/invlerp';
import randInt from '../../utils/randInt';
import strToTitleCase from '../../utils/strToTitleCase';

const COLOR_SCALE = {
  line: [
    [[0.0], [216, 56, 24]], // #d83818
    [[1.0], [253, 249, 237]], // #fdf9ed
  ],
  node: [
    [[0.0], [137, 84, 242]], // #8954f2
    [[0.5], [173, 216, 230]], // ##add8e6
    [[1.0], [87, 167, 115]], // #57a773
  ],
};
const WHITE = [255, 255, 255, 255];
const LAYER_NAME = 'DeckglLayer__GridHealth';
const ICON_ATLAS_SQUARE = '/node-square.png'; // In /public
const ICON_ATLAS_SQUARE_GLOW = '/node-square.glow.png'; // In /public
const ICON_ATLAS_CIRCLE_GLOW = '/node-circle.glow.png'; // In /public
const ICON_MAPPING = { square: { x: 0, y: 0, width: 202, height: 202, mask: false } };
const ICON_MAPPING_MASK = { square: { x: 0, y: 0, width: 202, height: 202, mask: true } };

const dataFilter = new DataFilterExtension({ filterSize: 1, fp64: false });

/** PLOT ATTRIBUTE GETTERS */

const getFilterValue = ({ timestamp }) => timestamp;

const getLineSourcePosition = ({ from_lat_long: fromGeo }) => fromGeo;

const getLineTargetPosition = ({ to_lat_long: toGeo }) => toGeo;

const getLineWidth = (/*d*/) => 9; // TODO: make variable width based on X value

const getLineDirectionWidth = () => 3; // getLineWidth() / 3;

const getLineDirectionColor = () => [12, 65, 255]; // #0C41FF

const getLineDirectionOpacity = () => 0.5;

const getLineDirectionTrailLength = () => 150000;

const getStrokeLineWidth = () => getLineWidth() * 1.25;

const getNodeSize = () => 12;

const getNodeExtSize = () => getNodeSize() * 2;

const getNodeBgSize = () => 68;

const getStrokeColor = () => WHITE;

const getLabelColor = () => WHITE;

const getIcon = () => 'square';

const isExt = ({ busType }) => busType === 'ext';

const getText = ({ bus_type: busType, name }) => (isExt({ busType }) ? name : strToTitleCase(name));

// /** getColor - Calculate color between two RGB values
//  * WIP
//  * @see https://stackoverflow.com/questions/649454/what-is-the-best-way-to-average-two-colors-that-define-a-linear-gradient
//  * @see https://stackoverflow.com/questions/9018016/how-to-compare-two-colors-for-similarity-difference
//  *
//  * @param {Array} RGB1
//  * @param {Array} RGB2
//  * @param {Number} d - divisor
//  *
//  */
// const getColor = ([R1, G1, B1], [R2, G2, B2], d) =>
//   [
//     // eslint-disable-next-line no-bitwise
//     Math.sqrt((R1 ^ (d + R2) ^ d) / d), // R new
//     // eslint-disable-next-line no-bitwise
//     Math.sqrt((G1 ^ (d + G2) ^ d) / d), // G new
//     // eslint-disable-next-line no-bitwise
//     Math.sqrt((B1 ^ (d + B2) ^ d) / d), // B new
//   ];

/** averageColor - Blended color at some percentage between two colors
 * @param {Array} rgb1
 * @param {Array} rgb2
 * @param {Number} fraction - Number 0-1
 * @returns {Array} - [R,G,B] "Color"
 */
const averageColor = (rgb1, rgb2, fraction) => {
  const predecessor = 1 - fraction;
  return [
    Math.round(rgb1[0] * fraction + rgb2[0] * predecessor),
    Math.round(rgb1[1] * fraction + rgb2[1] * predecessor),
    Math.round(rgb1[2] * fraction + rgb2[2] * predecessor),
  ];
};

/** fractionRelativetoPartX - In equally cut parts of a whole, calculate fraction relative to part
 * @param {Number} fraction - Number 0-1
 * @param {Number} x - Index of part 0-x
 * @param {Number} t - Total length of the set
 * @returns {Number} - Between 0 and 1
 */
const fractionRelativetoPartX = (fraction, x, t) => (fraction * 100 - (100 / t) * x) / (100 / t);

/** colorInRange - Feed scaleColor into averageColor and get the precise blended color value
 * @param {Array} colorScale - An Array of Arrays
 * @param {Number} Number 0-1
 */
const colorInRange = (range, fraction) => {
  let color;
  let relativeFraction;
  range.some((s, i, a) => {
    if (fraction === 0) color = [a[0][1]];
    else if (fraction === 1) color = [a[a.length - 1][1]];
    else if (fraction < s[0]) color = [a[i - 1][1], s[1]];
    if (range.length > 2 && color)
      relativeFraction = fractionRelativetoPartX(fraction, i, range.length);
    return color;
  });
  return color.length === 1
    ? color[0]
    : averageColor(color[0], color[1], relativeFraction || fraction); // 0 or blend
};

/** getScaleColor - Return normalized colors when values go over or under the normal scale
 * @param {Object} d - Item data
 * @param {Number} normalMin
 * @param {Number} normalMax
 * @param {string} scale - Which in COLOR_SCALE Object
 * @param {string} valProp - Property for value in data
 * @returns {Array} - [R,G,B] "Color"
 */
const getScaleColor = (d, normalMin, normalMax, scale, valProp) => {
  if (d[valProp] < normalMin) return COLOR_SCALE[scale][0][1];
  if (d[valProp] > normalMax) return COLOR_SCALE[scale][COLOR_SCALE[scale].length - 1][1];
  return colorInRange(COLOR_SCALE[scale], invlerp(normalMin, normalMax, d[valProp]));
};

/** getLineColor - RGB color value for Lines
 * @param {Object} d - Item data
 * @returns {Array} - [R,G,B] "Color"
 */
const getLineColor = (d) => getScaleColor(d, 0, 120, 'line', 'loading_percent');

/** getNodeColor - RGB color value for Nodes
 * @param {Object} d - Item data
 * @returns {Array} - [R,G,B] "Color"
 */
const getNodeColor = (d) => getScaleColor(d, -200, 200, 'node', 'p_mw');

/** getNodePosition - Convert data Object into Geo-Array
 * @param {Object} d - Item data
 * @returns {Array}
 */
const getNodePosition = ({ longitude, latitude }) => [longitude, latitude];

/** caseFilter - Keep relevant data
 * @param {string} scenarioCase
 * @param {Object} d - Item data
 * @returns {Boolean}
 */
const caseFilter = (scenarioCase, d) => d.case === scenarioCase;

/** DeckglLayer - Component View */
export class DeckglLayer extends CompositeLayer {
  renderLayers() {
    const { data, scenarioCase, timestamp, timestep, timeStep } = this.props;
    const lines = data.lines.filter(caseFilter.bind(null, scenarioCase));
    const nodes = { all: data.nodes.filter(caseFilter.bind(null, scenarioCase)) };
    nodes.nodesExt_stroke = nodes.all.filter(({ bus_type: busType }) => isExt({ busType }));
    nodes.nodes_stroke = nodes.all.filter(({ bus_type: busType }) => !isExt({ busType }));
    nodes.nodesExt = nodes.all.filter(({ bus_type: busType }) => isExt({ busType }));
    nodes.nodes = nodes.all.filter(({ bus_type: busType }) => !isExt({ busType }));

    return [
      // LINES
      // - colored lines
      lines.length
        ? new LineLayer(
            this.getSubLayerProps({
              id: `load_lines_${randInt()}`,
              data: lines,
              getWidth: getLineWidth,
              getColor: getLineColor,
              getSourcePosition: getLineSourcePosition,
              getTargetPosition: getLineTargetPosition,
              extensions: [dataFilter],
              filterRange: [timestamp, timestamp],
              getFilterValue,
              // Enable picking
              pickable: true,
            }),
          )
        : null,
      // - animated directional lines
      lines.length
        ? new TripsLayer(
            this.getSubLayerProps({
              id: `power_direction_lines_${randInt()}`,
              data: lines,
              widthMinPixels: getLineDirectionWidth(),
              getColor: getLineDirectionColor,
              opacity: getLineDirectionOpacity(),
              extensions: [dataFilter],
              filterRange: [timestamp, timestamp],
              getFilterValue,
              getTimestamps: (d) => d.waypoints.map((p) => p.timestamp),
              currentTime: timestamp,
              getPath: (d) => d.waypoints.map((p) => p.coordinates), // rounded: true,
              trailLength: getLineDirectionTrailLength(),
              shadowEnabled: true,
              // Enable picking
              pickable: true,
            }),
          )
        : null,

      // NODES
      // - non-colored node background icons
      nodes.nodesExt_stroke.length
        ? new IconLayer(
            this.getSubLayerProps({
              id: `nodesExt_stroke_${randInt()}`,
              data: nodes.nodesExt_stroke,
              getSize: getNodeBgSize(),
              iconAtlas: ICON_ATLAS_SQUARE_GLOW,
              iconMapping: ICON_MAPPING,
              getIcon,
              getPosition: getNodePosition,
              extensions: [dataFilter],
              filterRange: [timestamp, timestamp],
              getFilterValue,
              // Enable picking
              pickable: true,
            }),
          )
        : null,
      nodes.nodes_stroke.length
        ? new IconLayer(
            this.getSubLayerProps({
              id: `nodes_stroke_${randInt()}`,
              data: nodes.nodes_stroke,
              getSize: getNodeBgSize(),
              iconAtlas: ICON_ATLAS_CIRCLE_GLOW,
              iconMapping: ICON_MAPPING,
              getIcon,
              getPosition: getNodePosition,
              extensions: [dataFilter],
              filterRange: [timestamp, timestamp],
              getFilterValue,
              // Enable picking
              pickable: true,
            }),
          )
        : null,
      // - colored node icons
      nodes.nodesExt.length
        ? new IconLayer(
            this.getSubLayerProps({
              id: `nodesExt_${randInt()}`,
              data: nodes.nodesExt,
              getColor: getNodeColor,
              getSize: getNodeExtSize(),
              iconAtlas: ICON_ATLAS_SQUARE,
              iconMapping: ICON_MAPPING_MASK,
              getIcon,
              getPosition: getNodePosition,
              extensions: [dataFilter],
              filterRange: [timestamp, timestamp],
              getFilterValue,
              // Enable picking
              pickable: true,
            }),
          )
        : null,
      nodes.nodes.length
        ? new ScatterplotLayer(
            this.getSubLayerProps({
              id: `nodes_${randInt()}`,
              data: nodes.nodes,
              filled: true,
              getFillColor: getNodeColor,
              radiusMinPixels: getNodeSize(),
              getPosition: getNodePosition,
              extensions: [dataFilter],
              filterRange: [timestamp, timestamp],
              getFilterValue,
              // Enable picking
              pickable: true,
            }),
          )
        : null,

      // LABELS
      nodes.length
        ? new TextLayer({
            id: `labels_${randInt}`,
            data: nodes.all,
            fontFamily: 'Roboto',
            pickable: true,
            fontSettings: { sdf: true },
            outlineColor: [0, 0, 0, 128],
            outlineWidth: 1,
            getColor: getLabelColor,
            getPosition: getNodePosition, //(d) => d.coordinates,
            getText,
            getSize: 15,
            getAngle: 0,
            getTextAnchor: 'end',
            getAlignmentBaseline: 'center',
            getPixelOffset: [-10, -14],
          })
        : null,
    ];
  }
}

DeckglLayer.layerName = LAYER_NAME;
