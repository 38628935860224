import localAzFnURI from '../../utils/localAzFnUri';
import getConfig from '../../config';

const { REGIONS, SIMULATION_URI } = getConfig();

/** Define URIs used in request methods */
const uri = { simulation: localAzFnURI(SIMULATION_URI) };
uri.types = `${uri.simulation}/types?regions=${REGIONS.join(',')}`;
uri.mapConfig = `${uri.simulation}/mapConfig`;

export default {
  /** getSimulation - simulation requests
   * @param {string} scenario
   * @param {Object} options { {?Number}delay, {?Object}payload, {?string}simulationId}
   */
  async getSimulation(scenario, { simId, payload = {} } = {}) {
    const data = simId
      ? await fetch(`${uri.simulation}?scenario=${scenario}&sim_id=${simId}`) // GET for polling for status:complete
      : await fetch(`${uri.simulation}?scenario=${scenario}`, {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
        });
    const simulation = await data.json();
    return simulation;
  },
  async getTypes() {
    const data = await fetch(uri.types);
    const types = await data.json();
    return types;
  },
  async getMapConfig() {
    const data = await fetch(uri.mapConfig);
    const mapConfig = await data.json();
    return mapConfig;
  },
};
