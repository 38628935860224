/* eslint-disable no-restricted-globals */
/** scrollToTop - Unset URI anchor and Smooth UX focus/scroll to page-top */
const SCROLL_ARGS = { top: 0, behavior: 'smooth' };
export default function scrollToTop(args = SCROLL_ARGS) {
  const hashhash = (location.hash.split('/')[1] ?? '').split('#')[0].trim();
  if (hashhash) location.hash = hashhash; // Clear URI hash (check to avoid unnecessary scroll)

  window.scrollTo(args); // Smooth-scroll to top
}

export { SCROLL_ARGS };
