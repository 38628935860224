import { Redirect, HashRouter as Router, Route, Switch } from 'react-router-dom';
import Historical from '../pages/Historical';
import NotFound from '../pages/NotFound';
import RealTime from '../pages/RealTime';
import Search from '../pages/Search';
import Simulation from '../pages/Simulation';
// import getConfig from '../config';

// const { DEPLOYMENT_BASENAME } = getConfig(); // Not needed for HashRouter

/** Pass query-parameters to page/redirection
 * @example Use this pattern to preserve and pass URI data forward
 *  <Route
 *    path="/PageName"
 *    render={({ location }) => <Redirect to={{ ...location, pathname: '/SameOrDifferentPage' }} />}
 *  />
 */

export default function AppRouter({ injectedHooks }) {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/Real-Time" />
        </Route>

        <Route path="/Historical">
          <Historical />
        </Route>

        <Route path="/Real-Time">
          <RealTime />
        </Route>

        <Route path="/Simulation">
          <Simulation />
        </Route>

        <Route path="/Search">
          <Search />
        </Route>

        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}
