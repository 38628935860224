import React, { Component } from 'react';
import Switch from 'react-switch';

const noop = () => {};

class ToggleSwitch extends Component {
  constructor(props) {
    super();
    this.onChange = (props.onChange || noop).bind(this);
  }

  render() {
    const { checked } = this.props; // Rerender on prop-change

    return (
      <Switch
        checked={checked}
        onChange={this.onChange}
        onColor="#08b2e3"
        onHandleColor="#fff"
        handleDiameter={17}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={11}
        width={27}
        className="react-switch"
      />
    );
  }
}

export default ToggleSwitch;
